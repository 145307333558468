import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { Maybe } from 'src/types';
import config from './config';

const DELIMITER = ',';

export function numberWithCommas(x: Maybe<number>) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function renderDollarAmount(amount: number) {
  return `$${numberWithCommas(amount)}.00`;
}

export function getSanitizedString(content: string): string {
  return content.match(/[a-zA-Z0-9,.;:_'\s-]*/gi)?.join('') || '';
}

export function getAsciiString(content: string): string {
  // eslint-disable-next-line no-control-regex
  return content.replace(/[^\x00-\x7F]/g, '');
}

export function getSanitizedAsciiString(content: string): string {
  return getAsciiString(getSanitizedString(content));
}

export async function downloadAsCsv({
  headers,
  data,
  fileName,
}: {
  headers: string[];
  data: string;
  fileName?: string;
}) {
  const link = document.createElement('a');
  const name =
    fileName?.replaceAll('.', '_') || format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  const csvContent = `${headers.join(DELIMITER)}\n${data}`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  link.href = URL.createObjectURL(blob);
  link.download = name;
  document.body.appendChild(link);
  await link.click();
  document.body.removeChild(link);
}

export async function downloadAsJson({
  data,
  fileName,
}: {
  data: any;
  fileName?: string;
}) {
  const name =
    fileName?.replaceAll('.', '_') || format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  await FileSaver.saveAs(
    new Blob([JSON.stringify(data)]),
    [name, 'json'].join('.')
  );
}

export const formatFilename = (showname?: string) => {
  if (!showname) {
    return '';
  }
  let filename = showname.replace(/\s+/g, '');
  filename = filename.replace(/[.,/#!$%^&*;:{}=\-_`~()?']/g, '');
  filename = filename.replace(/\s{2,}/g, ' ');
  return `${config.showLogoDirectory}${filename}.jpeg`.toLowerCase();
};

export const formatHostSentiment = (score: number) => {
  if (score < -0.15) {
    return 'negative';
  } else if (score < -0.01) {
    return 'slightly negative';
  } else if (score <= 0.01) {
    return 'neutral';
  } else if (score <= 0.15) {
    return 'slightly positive';
  } else {
    return 'positive';
  }
};
