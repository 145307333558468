import { useState, useCallback, useEffect, useRef } from 'react';
import { decode } from 'html-entities';
import { debounce } from 'lodash';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import {
  IGarmScoreShowDetails,
  GarmShowsRequestBody,
  ShowEpisodesType,
  GarmScoresPerShowBody,
} from 'src/types';
import useEnvironment from 'src/hooks/useEnvironment';
import {
  getFilteredGarmShows,
  getFilteredGarmShowNames,
  getGarmScoresPerShow,
} from 'src/apis/garm';
import useAuth from 'src/hooks/useAuth';
import {
  convertRatings,
  getValidGarmScores,
  formatPodcastRating,
  formatPodcastRatingNumber,
} from 'src/utils';
import {
  PodcastExceptionTypes,
  PodcastsFilterType,
  ScoreFilterType,
  ScoreExceptionTypes,
} from 'src/types/filter';

const useDashboardData = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [searchText, setSearchText] = useState('');
  const { dashboardEnv, timeRange } = useEnvironment();
  const [loading, setLoading] = useState(false);
  const [currentShow, setCurrentShow] = useState<Nullable<string>>(null);
  const [pageGarmShows, setPageGarmShows] = useState<IGarmScoreShowDetails[]>(
    []
  );
  const [totalShowsCount, setTotalShowsCount] = useState<number>(0);
  const [showEpisodes, setShowEpisodes] =
    useState<Nullable<ShowEpisodesType>>(null);

  const {
    dashboardId,
    garmSourceFilters,
    selectedPublishers,
    selectedGenres,
    excludedGenres,
    selectedIABCategories,
    selectedLanguages,
    riskChangeFilter,
    enabledFilters,
    selectedCustomFilter,
    electionRange,
    selectedCustomTags,
  } = useDashboardFilters();
  const { isAuthenticated } = useAuth();

  const fetchFilteredGarmShows = useCallback(async (body: any) => {
    try {
      setLoading(true);
      const { data, totalCount } = await getFilteredGarmShows(body);
      setLoading(false);
      setTotalShowsCount(totalCount);
      const newGarmShows = data.map((item: any) => {
        const newItem = {
          ...item,
          iabCategories:
            item.iabCategories &&
            Object.keys(item.iabCategories)
              .map((key) => ({
                count: item.iabCategories[key],
                name: key,
              }))
              .sort((a: any, b: any) => b.count - a.count)
              .slice(0, 3),
          show: decode(item.show),
          showOnDB: item.show,
          listenerCount: formatPodcastRatingNumber(item.rating),
        };
        if (item.riskHistories && item.riskHistories.current) {
          Object.keys(item.riskHistories.current).forEach((key: string) => {
            const historyValues = item.riskHistories.current[key];
            if (historyValues) {
              if (key === 'electionsPercentage') {
                newItem[key] = {
                  value: historyValues.medianRiskValue,
                  up: historyValues.episodeCountHigherThanAverage,
                  down: historyValues.episodeCountLowerThanAverage,
                };
              } else {
                const score = historyValues.medianRiskValue;
                const oldScore =
                  item.riskHistories.prev && item.riskHistories.prev[key]
                    ? item.riskHistories.prev[key].medianRiskValue
                    : undefined;
                const updatedShow = score && oldScore && score !== oldScore;

                newItem[key] = {
                  ...historyValues,
                  score,
                  oldScore,
                  updatedShow,
                };
              }
            }
          });
        }
        return newItem;
      });

      setPageGarmShows(newGarmShows);
    } catch (error) {
      setPageGarmShows([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchFilteredGarmShowNames = async () => {
    if (isAuthenticated && dashboardId) {
      const body: GarmShowsRequestBody = {};
      if (searchText) {
        if (dashboardEnv === 'Host') {
          body.hostSearch = searchText;
        } else {
          if (searchText.startsWith('#')) {
            body.genreSearch = searchText.slice(1);
          } else {
            body.search = searchText;
          }
        }
      }
      if (garmSourceFilters.length) {
        body.sources = garmSourceFilters;
      }
      if (selectedGenres.length) {
        body.genres = selectedGenres;
      }
      if (excludedGenres.length) {
        body.excludedGenres = excludedGenres;
      }
      if (selectedCustomTags.length) {
        body.customTags = selectedCustomTags;
      }
      if (selectedPublishers.length) {
        body.publishers = selectedPublishers;
      }
      if (selectedIABCategories.length) {
        body.iabCategories = [
          ...selectedIABCategories.map((item) => item.name.toLowerCase()),
          ...selectedIABCategories.map((item) => item.name),
        ];
      }
      if (selectedLanguages.length) {
        body.languages = selectedLanguages;
      }
      if (riskChangeFilter) {
        body.riskLevelDelta = riskChangeFilter;
      }
      if (timeRange) {
        body.dateRange = {
          to: timeRange.to.toISOString(),
          from: timeRange.from.toISOString(),
        };
      }
      if (dashboardEnv === 'Non-English') {
        body.contentLanguageKind = 'NON-EN';
      }
      const garmExceptions: any = {};
      if (enabledFilters && enabledFilters.length) {
        enabledFilters.forEach((filter) => {
          const filterValue: number[] = filter.value as number[];
          if (filterValue && filter.jsonKeyRisk) {
            if (filter.jsonKeyRisk === 'audience') {
              const values = convertRatings(filterValue);
              if (values) {
                body.listenerCountRange = values;
              }
            } else {
              const values = getValidGarmScores(filterValue);
              if (values) {
                garmExceptions[filter.dashboardFilterKey] = { default: values };
              }

              if (selectedCustomFilter) {
                const customFilter =
                  selectedCustomFilter.data.scoreFilters[filter.title];
                if (customFilter) {
                  garmExceptions[filter.dashboardFilterKey] = {
                    default: getValidGarmScores(customFilter.score),
                  };
                  if (customFilter.content.length) {
                    const content: any = {};
                    customFilter.content.forEach((item: ScoreFilterType) => {
                      if (item.exceptions?.length) {
                        content[
                          item.type === ScoreExceptionTypes.Genre
                            ? 'genre'
                            : 'garmReasoning'
                        ] = item.exceptions;
                      }
                    });
                    garmExceptions[filter.dashboardFilterKey] = {
                      ...garmExceptions[filter.dashboardFilterKey],
                      ...content,
                    };
                  }
                }
              }
            }
          }
        });
      }

      body.garmExceptions = garmExceptions;
      body.electionsExceptions = {
        percentRange: electionRange,
      };

      try {
        const { showNames } = await getFilteredGarmShowNames(body);
        return showNames || [];
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  const debounceFetchFilteredGarmShows = useRef(
    debounce(async (body: GarmShowsRequestBody) => {
      fetchFilteredGarmShows(body);
    }, 900)
  ).current;

  const fetchGarmScoresPerShow = useCallback(
    async (show: string, body: GarmScoresPerShowBody, isUnscored: boolean) => {
      try {
        setLoading(true);
        const data = await getGarmScoresPerShow(show, {
          ...body,
          isUnscored,
        });
        const newGarmScores = data.data.map((item: any) => {
          const newItem = {
            ...item,
            Listeners: formatPodcastRating(item.Listeners),
          };

          return newItem;
        });
        setShowEpisodes({ ...data, data: newGarmScores });
      } catch (error) {
        setShowEpisodes(null);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const onChangeShow = useCallback(
    async (
      show: Nullable<string>,
      body: GarmScoresPerShowBody,
      isUnscored = false
    ) => {
      setCurrentShow(show);
      if (show) {
        await fetchGarmScoresPerShow(show, body, isUnscored);
      }
    },
    [fetchGarmScoresPerShow]
  );

  const updateDashboardShows = () => {
    if (isAuthenticated && dashboardId) {
      const body: GarmShowsRequestBody = {
        page,
        pageSize: rowsPerPage,
      };
      if (orderBy && order) {
        body.sortBy =
          orderBy === 'Name' || orderBy === 'rating'
            ? orderBy.toLowerCase()
            : orderBy === 'US Elections'
            ? 'electionsPercentage'
            : enabledFilters.filter((item) => item.title === orderBy)[0]
                .dashboardFilterKey;
        body.sortOrder = order === 'asc' ? 'ASC' : 'DESC';
      }
      if (searchText) {
        if (dashboardEnv === 'Host') {
          body.hostSearch = searchText;
        } else {
          if (searchText.startsWith('#')) {
            body.genreSearch = searchText.slice(1);
          } else {
            body.search = searchText;
          }
        }
      }
      if (garmSourceFilters.length) {
        body.sources = garmSourceFilters;
      }
      if (selectedGenres.length) {
        body.genres = selectedGenres;
      }
      if (excludedGenres.length) {
        body.excludedGenres = excludedGenres;
      }
      if (selectedCustomTags.length) {
        body.customTags = selectedCustomTags;
      }
      if (selectedPublishers.length) {
        body.publishers = selectedPublishers;
      }
      if (selectedIABCategories.length) {
        body.iabCategories = [
          ...selectedIABCategories.map((item) => item.name.toLowerCase()),
          ...selectedIABCategories.map((item) => item.name),
        ];
      }
      if (selectedLanguages.length) {
        body.languages = selectedLanguages;
      }
      if (riskChangeFilter) {
        body.riskLevelDelta = riskChangeFilter;
      }
      if (timeRange) {
        body.dateRange = {
          to: timeRange.to.toISOString(),
          from: timeRange.from.toISOString(),
        };
      }
      if (dashboardEnv === 'Non-English') {
        body.contentLanguageKind = 'NON-EN';
      }
      const garmExceptions: any = {};
      if (enabledFilters && enabledFilters.length) {
        enabledFilters.forEach((filter) => {
          const filterValue: number[] = filter.value as number[];
          if (filterValue && filter.jsonKeyRisk) {
            if (filter.jsonKeyRisk === 'audience') {
              const values = convertRatings(filterValue);
              if (values) {
                body.listenerCountRange = values;
              }
            } else {
              const values = getValidGarmScores(filterValue);

              if (values) {
                garmExceptions[filter.dashboardFilterKey] = { default: values };
              }
              if (selectedCustomFilter) {
                const customFilter =
                  selectedCustomFilter.data.scoreFilters[filter.title];
                if (customFilter) {
                  garmExceptions[filter.dashboardFilterKey] = {
                    default: getValidGarmScores(customFilter.score),
                  };
                  if (customFilter.content.length) {
                    const content: any = {};
                    customFilter.content.forEach((item: ScoreFilterType) => {
                      if (item.exceptions?.length) {
                        content[
                          item.type === ScoreExceptionTypes.Genre
                            ? 'genre'
                            : 'garmReasoning'
                        ] = item.exceptions;
                      }
                    });
                    garmExceptions[filter.dashboardFilterKey] = {
                      ...garmExceptions[filter.dashboardFilterKey],
                      ...content,
                    };
                  }
                }
              }
            }
          }
        });
      }

      body.garmExceptions = garmExceptions;
      body.electionsExceptions = {
        percentRange: electionRange,
      };

      if (selectedCustomFilter?.data.podcastFilters) {
        const include = selectedCustomFilter?.data.podcastFilters.find(
          (item: PodcastsFilterType) =>
            item.type === PodcastExceptionTypes.Include
        ).exceptions;
        const exclude = selectedCustomFilter?.data.podcastFilters.find(
          (item: PodcastsFilterType) =>
            item.type === PodcastExceptionTypes.Exclude
        ).exceptions;

        body.podcastExceptions = {
          include,
          exclude,
        };
      }

      fetchFilteredGarmShows(body);
    }
  };

  useEffect(() => {
    if (isAuthenticated && dashboardId) {
      const body: GarmShowsRequestBody = {
        page,
        pageSize: rowsPerPage,
      };
      if (orderBy && order) {
        body.sortBy =
          orderBy === 'Name' || orderBy === 'rating'
            ? orderBy.toLowerCase()
            : orderBy === 'US Elections'
            ? 'electionsPercentage'
            : enabledFilters.filter((item) => item.title === orderBy)[0]
                .dashboardFilterKey;
        body.sortOrder = order === 'asc' ? 'ASC' : 'DESC';
      }
      if (searchText) {
        if (dashboardEnv === 'Host') {
          body.hostSearch = searchText;
        } else {
          if (searchText.startsWith('#')) {
            body.genreSearch = searchText.slice(1);
          } else {
            body.search = searchText;
          }
        }
      }
      if (garmSourceFilters.length) {
        body.sources = garmSourceFilters;
      }
      if (selectedGenres.length) {
        body.genres = selectedGenres;
      }
      if (excludedGenres.length) {
        body.excludedGenres = excludedGenres;
      }
      if (selectedCustomTags.length) {
        body.customTags = selectedCustomTags;
      }
      if (selectedPublishers.length) {
        body.publishers = selectedPublishers;
      }
      if (selectedIABCategories.length) {
        body.iabCategories = [
          ...selectedIABCategories.map((item) => item.name.toLowerCase()),
          ...selectedIABCategories.map((item) => item.name),
        ];
      }
      if (selectedLanguages.length) {
        body.languages = selectedLanguages;
      }
      if (riskChangeFilter) {
        body.riskLevelDelta = riskChangeFilter;
      }
      if (timeRange) {
        body.dateRange = {
          to: timeRange.to.toISOString(),
          from: timeRange.from.toISOString(),
        };
      }
      if (dashboardEnv === 'Non-English') {
        body.contentLanguageKind = 'NON-EN';
      }
      const garmExceptions: any = {};
      if (enabledFilters && enabledFilters.length) {
        enabledFilters.forEach((filter) => {
          const filterValue: number[] = filter.value as number[];
          if (filterValue && filter.jsonKeyRisk) {
            if (filter.jsonKeyRisk === 'audience') {
              const values = convertRatings(filterValue);
              if (values) {
                body.listenerCountRange = values;
              }
            } else {
              const values = getValidGarmScores(filterValue);

              if (values) {
                garmExceptions[filter.dashboardFilterKey] = { default: values };
              }
              if (selectedCustomFilter) {
                const customFilter =
                  selectedCustomFilter.data.scoreFilters[filter.title];
                if (customFilter) {
                  garmExceptions[filter.dashboardFilterKey] = {
                    default: getValidGarmScores(customFilter.score),
                  };
                  if (customFilter.content.length) {
                    const content: any = {};
                    customFilter.content.forEach((item: ScoreFilterType) => {
                      if (item.exceptions?.length) {
                        content[
                          item.type === ScoreExceptionTypes.Genre
                            ? 'genre'
                            : 'garmReasoning'
                        ] = item.exceptions;
                      }
                    });
                    garmExceptions[filter.dashboardFilterKey] = {
                      ...garmExceptions[filter.dashboardFilterKey],
                      ...content,
                    };
                  }
                }
              }
            }
          }
        });
      }

      body.garmExceptions = garmExceptions;
      body.electionsExceptions = {
        percentRange: electionRange,
      };

      if (selectedCustomFilter?.data.podcastFilters) {
        const include = selectedCustomFilter?.data.podcastFilters.find(
          (item: PodcastsFilterType) =>
            item.type === PodcastExceptionTypes.Include
        ).exceptions;
        const exclude = selectedCustomFilter?.data.podcastFilters.find(
          (item: PodcastsFilterType) =>
            item.type === PodcastExceptionTypes.Exclude
        ).exceptions;

        body.podcastExceptions = {
          include,
          exclude,
        };
      }

      debounceFetchFilteredGarmShows(body);
    }

    return () => {
      debounceFetchFilteredGarmShows.cancel();
    };
  }, [
    debounceFetchFilteredGarmShows,
    isAuthenticated,
    dashboardId,
    searchText,
    dashboardEnv,
    page,
    rowsPerPage,
    order,
    orderBy,
    garmSourceFilters,
    selectedPublishers,
    selectedIABCategories,
    selectedLanguages,
    riskChangeFilter,
    enabledFilters,
    selectedGenres,
    excludedGenres,
    selectedCustomFilter,
    timeRange,
    electionRange,
    selectedCustomTags,
  ]);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRequestSort = (property: string, index: number) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  return {
    currentShow,
    totalShowsCount,
    showEpisodes,
    order,
    orderBy,
    page,
    pageGarmShows,
    rowsPerPage,
    searchText,
    loading,
    fetchFilteredGarmShowNames,
    onChangeShow,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onRequestSort: handleRequestSort,
    onChangeSearchText: handleChangeSearchText,
    updateDashboardShows,
  };
};

export default useDashboardData;
