import { baseAPICreator } from './apiCreator';

const episodeApi = baseAPICreator(
  '/episode',
  undefined,
  'https://api-episodes-dev.thebarometer.co/api'
);

export const parseEpisodesOfShow = async (
  show: string
): Promise<{ status: string; message: string }> => {
  const { data } = await episodeApi.get(`${encodeURIComponent(show)}/parse`);
  return data;
};
