import { baseAPICreator } from './apiCreator';
import {
  ShowRSSProps,
  ReviewDataProps,
  ReviewedDataProps,
  SuggestedSuccessShowProps,
  ScoringRequestHistoryProps,
  ScoringRequestRequestProps,
} from 'src/types/selfScoring';

const selfServiceApi = baseAPICreator('/self-service');

export const getScoringImportedShows = async (
  body: Record<string, string>[]
): Promise<{ data: ReviewDataProps }> => {
  const { data } = await selfServiceApi.post('review-request', body);
  return { data: data };
};

export const getScoringReviewedShows = async (
  body: ReviewedDataProps
): Promise<{
  data: {
    missing: SuggestedSuccessShowProps[];
    success: SuggestedSuccessShowProps[];
  };
}> => {
  const { data } = await selfServiceApi.post('review-selections', {
    suggested: body.suggested,
    missing: [...body.invalid, ...body.unmatched],
  });
  return { data: data };
};

export const submitRequestSelfScoring = async (body: {
  dashboardId: string;
  shows: SuggestedSuccessShowProps[];
}): Promise<{ data: boolean }> => {
  await selfServiceApi.post('submit-request', body);
  return { data: true };
};

export const getSelfScoringHistory = async (body: {
  sortBy: string;
  sortOrder: string;
  page: number;
  pageSize: number;
}): Promise<{
  data: ScoringRequestHistoryProps;
}> => {
  const { data } = await selfServiceApi.post('history', body);
  return { data };
};

export const getSelfScoringRequestById = async (
  id: string
): Promise<{
  data: ScoringRequestRequestProps;
}> => {
  const { data } = await selfServiceApi.get(id);
  return { data };
};

export const sendManualReviews = async (
  body: ShowRSSProps[]
): Promise<void> => {
  await selfServiceApi.post('mail', {
    shows: body.map((data) => ({
      showName: data.showName,
      rssFeed: data.rss,
    })),
  });
};

export const scoringEpisode = async (
  episodeId: string,
  dashboardId: string,
  rssFeedUrl: string
): Promise<{
  data: ScoringRequestHistoryProps;
}> => {
  const { data } = await selfServiceApi.post('singleton', {
    episodeId,
    rssFeedUrl,
    dashboardId,
  });
  return { data };
};
