import React from 'react';
import { Box, styled, TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';

import { useStyles } from '../common';
import Button from 'src/components/NewButton';

export const EpisodeRowContainer = styled(TableRow)(() => ({
  '&:hover': {
    opacity: 0.8,
  },
}));
export const CellContent = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

interface EpisodeRowProps {
  buttonSpan: number;
  data: { [x: string]: any };
  handleScore: (episodeId: string) => void;
  scoringList: string[];
}

const UnscoredEpisodeRow = ({
  data,
  buttonSpan,
  handleScore,
  scoringList,
}: EpisodeRowProps) => {
  const classes = useStyles();

  let publishedAt = null;

  if (data['Episode Date']) {
    try {
      publishedAt = format(new Date(data['Episode Date']), 'MM/dd/yyyy');
    } catch {
      publishedAt = '-';
    }
  } else {
    publishedAt = '-';
  }

  return (
    <EpisodeRowContainer
      style={{
        borderColor: '#E1E1E1',
      }}
    >
      <TableCell
        style={{
          width: '350px',
          minWidth: '320px',
          color: '#000',
          padding: '10px',
          fontSize: '16px',
          borderRight: '1px solid #E1E1E1',
        }}
        className={classes.tableFont}
      >
        <CellContent>{data['Episode Title']}</CellContent>
      </TableCell>
      <TableCell
        style={{
          width: '120px',
          minWidth: '120px',
          color: '#000',
          padding: '10px',
          fontSize: '16px',
        }}
        align='center'
        className={classes.tableFont}
      >
        <CellContent>{publishedAt}</CellContent>
      </TableCell>
      <TableCell
        style={{
          color: '#000',
          padding: '10px 20px',
        }}
        align='left'
        className={classes.scoreCell}
        colSpan={buttonSpan}
      >
        <Button
          height={32}
          onClick={() => {
            handleScore(data.id);
          }}
          disabled={scoringList.includes(data.id) || data.status === 'queued'}
        >
          {scoringList.includes(data.id) || data.status === 'queued'
            ? 'Scoring...'
            : 'Score'}
        </Button>
      </TableCell>
    </EpisodeRowContainer>
  );
};

export default UnscoredEpisodeRow;
