import axios from 'axios';
import Swal from 'sweetalert2';

import config from 'src/utils/config';
import { getToken, removeToken } from './auth';

export const baseAPICreator = (
  basePath: string,
  headers?: unknown,
  configUrl?: string
) => {
  const instance = axios.create({
    baseURL: (configUrl || config.apiUrl) + basePath,
    headers: headers || {
      Accept: 'application/json',
    },
  });

  instance.interceptors.request.use((request) => {
    const token = getToken();
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  // Response interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      const { status } = error.response;
      const originToken = sessionStorage.getItem('token');

      if (status === 408 && originToken) {
        Swal.fire({
          icon: 'warning',
          title: 'Maintenance',
          text: 'Temporarily down for maintenance.',
          reverseButtons: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        });
      } else if (status === 401 && originToken) {
        Swal.fire({
          icon: 'warning',
          title: 'Token Expired',
          text: 'Your token is expired.',
          reverseButtons: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(() => {
          removeToken();
          window.location.href = '/login';
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
