import { FC, useState, MouseEventHandler } from 'react';
import {
  Button,
  Grid,
  styled,
  Typography,
  Menu,
  MenuItem,
  Dialog,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import { Downloading } from 'src/components/Downloading';
import Spinner from 'src/components/Spinner';
import useEnvironment from 'src/hooks/useEnvironment';
import { useDownload } from 'src/containers/dashboard/garm/hooks';
import useAuth from 'src/hooks/useAuth';

import { ReactComponent as DownloadIcon } from 'src/assets/dashboard/download.svg';

export const MenuRowContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  gap: theme.spacing(4),

  '& svg': {
    fontSize: 18,
    letterSpacing: '0.46px',
  },
  '& .MenuOption': {
    marginLeft: theme.spacing(2.2),
  },
}));

export const NewDashboardButton = styled(Button)(() => ({
  borderRadius: 24,
  boxShadow: '0px 0px 0px 1px #E0E0E0',
  fontSize: 14,
  fontWeight: 500,
}));

export const ExportButton = styled(Button)(() => ({
  borderRadius: 8,
  border: '1px solid #C3C3C7',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  padding: '6px 8px',
}));

export const SaveButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

export const MenuText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  textTransform: 'uppercase',
  marginLeft: theme.spacing(1.1),
}));

export const DashboardTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 30,
  lineHeight: '40px',
}));

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    margin: 0,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));

interface MenuRowProps {
  totalShowsCount: number;
  order: 'desc' | 'asc' | undefined;
  orderBy: string;
  searchText: string;
  dashboardId: Nullable<string>;
  dashboardName: string;
  onCreate: VoidFunction;
  onNew: VoidFunction;
  onUpdate: VoidFunction;
  updateDashboardShows: VoidFunction;
}

const MenuRow: FC<MenuRowProps> = ({
  totalShowsCount,
  order,
  orderBy,
  searchText,
  dashboardId,
  dashboardName,
  onCreate,
  onNew,
  onUpdate,
  updateDashboardShows,
}) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);
  const [cancelDownload, setCancelDownload] = useState<boolean>(false);
  const isViewer = user?.role === 'view';
  const { dashboardEnv } = useEnvironment();

  const {
    onDownloadAsJson,
    onDownloadAsCsv,
    onDownloadAllShows,
    onDownloadAllEpisodes,
    csvDownloading,
    jsonDownloading,
    downloadProgress,
    cancelDownloading,
    showsDownloading,
  } = useDownload({
    searchText,
    dashboardEnv,
    totalShowsCount,
    order,
    orderBy,
    setCancelDownload,
  });

  const handleOpenExpert: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExport = () => {
    setAnchorEl(null);
  };

  return (
    <MenuRowContainer
      container
      item
      justifyContent='space-between'
      alignItems='center'
      xs={12}
    >
      <Grid
        container
        item
        alignItems='center'
        style={{ width: 'auto', gap: 24, paddingLeft: 24 }}
      >
        <DashboardTitle>{dashboardName}</DashboardTitle>
        <NewDashboardButton
          color='primary'
          disableElevation
          variant='contained'
          size='small'
          onClick={() => {
            updateDashboardShows();
          }}
        >
          Refresh Data
        </NewDashboardButton>
      </Grid>
      <Grid item>
        <Grid container alignItems='center'>
          {!isViewer ? (
            <>
              {dashboardId && (
                <Grid item className='MenuOption'>
                  <SaveButton
                    color='primary'
                    disableElevation
                    startIcon={<SaveIcon />}
                    variant='text'
                    size='small'
                    onClick={onUpdate}
                  >
                    Save
                  </SaveButton>
                </Grid>
              )}

              <Grid item className='MenuOption'>
                <SaveButton
                  color='primary'
                  disableElevation
                  startIcon={<SaveIcon />}
                  variant='text'
                  size='small'
                  onClick={onCreate}
                >
                  Save As New
                </SaveButton>
              </Grid>

              <Grid item className='MenuOption'>
                <Grid container alignItems='center'>
                  <NewDashboardButton
                    color='primary'
                    disableElevation
                    startIcon={<AddIcon />}
                    variant='contained'
                    size='small'
                    onClick={onNew}
                  >
                    Dashboard
                  </NewDashboardButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item className='MenuOption'>
            <Grid container alignItems='center'>
              <ExportButton
                color='secondary'
                disableElevation
                startIcon={<DownloadIcon />}
                variant='outlined'
                size='small'
                onClick={handleOpenExpert}
              >
                <span
                  style={{
                    color: '#2D2D38',
                    fontSize: 14,
                    lineHeight: '20px',
                    textTransform: 'capitalize',
                  }}
                >
                  Export
                </span>
              </ExportButton>

              <Menu
                id='dashboard-menu'
                aria-labelledby='dashboard-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseExport}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseExport();
                    setCancelDownload(false);
                    onDownloadAsCsv();
                  }}
                >
                  CSV
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseExport();
                    setCancelDownload(false);
                    onDownloadAsJson();
                  }}
                >
                  JSON
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseExport();
                    setCancelDownload(false);
                    onDownloadAllShows();
                  }}
                >
                  CSV Shows Only
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseExport();
                    setCancelDownload(false);
                    onDownloadAllEpisodes();
                  }}
                >
                  CSV Episodes Only
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={(csvDownloading || jsonDownloading) && !cancelDownload}
        fullWidth
      >
        <Downloading
          value={downloadProgress}
          title={`Download shows and episodes of ${dashboardName}`}
          handleCancel={() => {
            cancelDownloading.current = true;
            setCancelDownload(true);
          }}
        />
      </Dialog>
      <BootstrapDialog open={showsDownloading} fullWidth>
        <Spinner />
      </BootstrapDialog>
    </MenuRowContainer>
  );
};

export default MenuRow;
